import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UsuarioService } from '../services/usuario/usuario.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario } from '../models/usuario';
import { Router } from '@angular/router';
import { ResultadoWS } from '../models/resultadoWS';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VariablesServices } from '../config/variables';
import { VariablesEntorno } from '../models/variablesEntorno';
import { LogService } from '../services/log/log.service';
import { LogInterface } from '../interfaces/log.interface';
import { SocketsService } from '../services/web-sockets/sockets.service';
import { environment } from '../../environments/environment';
import Keyboard from 'simple-keyboard';
import { PublicidadService } from '../services/publicidad/publicidad.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  formulario: FormGroup;
  objUsuario: Usuario;
  email: string;
  recuerdame = false;
  ResultadoWS: ResultadoWS;
  @ViewChild('name') name: ElementRef;
  @ViewChild('password') password: ElementRef;
  keyboard: Keyboard;
  showTeclado = false;
  button = '';
  constructor(
    private service:
      UsuarioService,
    private router: Router,
    private snackBar: MatSnackBar,
    private env: VariablesServices,
    private log: LogService,
    private socket: SocketsService,
    private PublicidadService: PublicidadService,

  ) {



  }

  getModo() {
    return environment.FLAG_AREA;
  }

  ngOnDestroy() {


  }

  refresh() {
    window.location.reload();
  }

  ngOnInit() {

    this.email = localStorage.getItem('name') || '';

    this.formulario = new FormGroup({
      name: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      defaultCheck1: new FormControl(true, Validators.required),
      defaultCheck2: new FormControl(true, Validators.required),

    });
  }

  configurarApp() {

    const modbuss = this.formulario.get('defaultCheck1').value;
    const pos = this.formulario.get('defaultCheck2').value;
    // console.log(this.formulario.value)
    const datos_rita = localStorage.getItem('rita');
    let waka = JSON.parse(datos_rita);

    if( waka.rita_id == 3 ){
      localStorage.setItem('modbuss', modbuss);
      localStorage.setItem('pos', 'false');
    }
    else{
      localStorage.setItem('modbuss', modbuss);
      localStorage.setItem('pos', pos);
    }

  }
  login() {
    if (this.validarFormulario()) { // valida los inputs del formulario

      
      this.getAutenticar().then(() => { // promesa ,epsera hasta obtener el token para cnsultar ws de los datos del usuario
        this.service.getUsuario().subscribe((res: any) => { // si devuelve una respuesta true , ejecuta el codigo interno        
          this.service.getVariablesEntorno().subscribe((req: any) => {

            if (req.payload.length > 0) {
              const env: VariablesEntorno = req.payload[0];
              localStorage.setItem('rita', JSON.stringify(req.rita));
              localStorage.setItem('entorno', JSON.stringify(req.payload[0]));
              console.log(req);
              this.setEnvironment(env);

              this.configurarApp();

              if (res.estado) {
                this.service.usuario.usuario_id = res.payload.usuario_id;
                this.service.usuario.name = res.payload.name;
                localStorage.setItem('usuario', JSON.stringify(res.payload));
                console.log(res);
                this.socket.configurarUsuario();


                let rita_id_actual = req.rita.rita_id;
                let rita_codigo_actual = req.rita.rita_codigo;
                let token_actual: any = localStorage.getItem('token');

                console.log("rita_id_actual", rita_id_actual)
                console.log("rita_codigo_actual", rita_codigo_actual)
                // console.log("token_actual", token_actual)

                //Aqui agregar consumo de WS local para publicidades



                this.loginRitaPublicidad(rita_id_actual, rita_codigo_actual, token_actual);
                /* agregaando redirect */
                /*  this.router.navigate(['/']); */


              } else {
                this.snackBar.open(res.mensaje, 'Cerrar', { duration: this.env.DURATIONSNACK });
                const error: LogInterface = {
                  log_json: JSON.stringify(req),
                  log_mensaje: res.mensaje

                }
                this.log.registrarLog(error).subscribe(res => {
                  console.log(res);
                });
              }
            } else {
              this.service.clearLocalStorahe();
              this.snackBar.open('No se cargaron las variables de entorno', 'Cerrar', { duration: this.env.DURATIONSNACK });

              const error: LogInterface = {
                log_json: JSON.stringify(res),
                log_mensaje: 'No se cargaron las variables de entorno'

              }
              this.log.registrarLog(error).subscribe(res => {
                console.log(res);
              });
            }

          }, err => {
            this.snackBar.open(err, 'cerrar', { duration: this.env.DURATIONSNACK });

            const error: LogInterface = {
              log_json: JSON.stringify(err),
              log_mensaje: 'No se cargaron las variables de entorno'

            }
            this.log.registrarLog(error).subscribe(res => {
              console.log(res);
            });

          });
        }, err => {
          this.snackBar.open(err, 'cerrar', { duration: this.env.DURATIONSNACK });
          const error: LogInterface = {
            log_json: JSON.stringify(err),
            log_mensaje: 'No se cargo el usuario'

          }
          this.log.registrarLog(error).subscribe(res => {
            console.log(res);
          });

        });
      }).catch(er => {
        this.snackBar.open(er, 'cerrar', { duration: this.env.DURATIONSNACK });
      });

    }
  }

  setEnvironment(env: VariablesEntorno) {
    localStorage.setItem('DURATIONSNACK', env.DURATIONSNACK.toString());
    localStorage.setItem('INTERVALO_PETICIONES', env.INTERVALO_PETICIONES.toString());
    localStorage.setItem('MOD_INTERVALO_PETICIONES', env.MOD_INTERVALO_PETICIONES.toString());
    localStorage.setItem('MOD_NUMERO_MAX_PETICIONES', env.MOD_NUMERO_MAX_PETICIONES.toString());
    localStorage.setItem('NUMERO_MAX_PETICIONES', env.NUMERO_MAX_PETICIONES.toString());
    localStorage.setItem('TIEMPO_PANTALLA_CARGA', env.TIEMPO_PANTALLA_CARGA.toString());
    localStorage.setItem('TOKEN_DIAS', env.TOKEN_DIAS.toString());
    localStorage.setItem('CELULAR_SOPORTE_TECNICO', env.CELULAR_SOPORTE_TECNICO);
    localStorage.setItem('MODBUS_ADDRESS_ERROR', env.MODBUS_ADDRESS_ERROR.toString());
    localStorage.setItem('MODBUS_ADDRESS_ESTADO', env.MODBUS_ADDRESS_ESTADO.toString());


  }
  /* metodo para ejecutar el login */
  getAutenticar(): Promise<boolean> {
    let error = new ResultadoWS();
    return new Promise((resolve, reject) => {
      this.objUsuario = this.formulario.value;
      this.service.login(this.objUsuario).subscribe((res: ResultadoWS) => {
        if (res.estado) {
          let strToken = `${res.payload.access_token}`;
          let expires_in = res.payload.expires_in;
          this.service.guardarStorage(strToken, expires_in);
          this.service.tokenx = strToken;
          this.service.tokenExpire = res.payload.expires_in;
          resolve(true);
        }
        else {
          reject(res.mensaje);
        }
      }, error => {
        reject(error);
      });
    });
  }


  blur() {
    let el: any = document.getElementsByClassName('keyboard-container visible');
    if (el.length > 0) {
      el[0].classList.remove("visible");

    }
  }


  validarFormulario() {

    this.setValues();
    this.blur();
    if (this.formulario.controls['name'].invalid) {
      this.snackBar.open('Ingrese usuario', 'cerrar', { duration: this.env.DURATIONSNACK });
      return false;
    } else if (this.formulario.controls['password'].invalid) {
      this.snackBar.open('Ingrese contraseña', 'cerrar', { duration: this.env.DURATIONSNACK });
      return false;
    } else {

      return true;
    }

  }


  setValues() { // requerido por el teclado virtual
    this.formulario.controls['name'].setValue(this.name.nativeElement.value);
    this.formulario.controls['password'].setValue(this.password.nativeElement.value);
  }


  input = 0;
  abrirTeclado(id: number) {
    this.input = id;
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button)
    });
    this.keyboard.setOptions({
      mergeDisplay: true,
      display: {
        '{bksp}': 'Eliminar',
      },
    });

    this.showTeclado = true;

  }
  cerrarTeclado() {
    this.showTeclado = false;
    this.keyboard.destroy();
  }




  onChange = (input: string) => {
    console.log(input)
    if (this.button !== '{bksp}') {
      if (this.input === 1) {
        this.formulario.get('name').setValue(this.formulario.get('name').value + input[input.length - 1]);
      }
      if (this.input === 2) {
        this.formulario.get('password').setValue(this.formulario.get('password').value + input[input.length - 1]);
      }
    }


    console.log(this.formulario.value)
  }

  onKeyPress = (button: string) => {
    this.button = button;
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    };
    if (button === '{bksp}') {

      if (this.input === 1) {

        this.formulario.get('name').setValue('');
      }
      if (this.input === 2) {
        /*       let input1 = this.formulario.get('password').value + '';
              input1 = input1.substring(0, input1.length - 1); */
        this.formulario.get('password').setValue('');
      }

    }
  }

  onInputChange = (event: any) => {

    if (this.input === 1) {
      let input1 = this.formulario.get('name').value + '';
      input1 = input1.substring(0, input1.length - 1);
      this.formulario.get('name').setValue(event.target.value);
    }
    if (this.input === 2) {
      let input1 = this.formulario.get('password').value + '';
      input1 = input1.substring(0, input1.length - 1);
      this.formulario.get('password').setValue(event.target.value);
    }

  }

  handleShift = () => {
    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  }

  loginRitaPublicidad(rita_id, rita_codigo, token) {

    this.PublicidadService.validarLoginRita(rita_id, rita_codigo, token).subscribe((reqPub: any) => {
      console.log("ingreso correctamente");
      console.log("reqPub", reqPub);
      this.router.navigate(['/']);
    }, err => {
      console.log(err)
      this.snackBar.open(err, 'cerrar', { duration: this.env.DURATIONSNACK });

      const error: LogInterface = {
        log_json: JSON.stringify(err),
        log_mensaje: 'No se cargaron las variables de publicidad'

      }
      //En caso nose pueda autenticar con el node local, deslogear
      this.service.logout();
      // this.log.registrarLog(error).subscribe(res => {
      //   console.log(res);
      // });

    });
  }


}
